import get from 'lodash/get'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import React from 'react'

import { SiteDocumentation, HomeBrands, HomeNewsLetter, PageServices } from '../components'

import { Container, Col, Row } from 'reactstrap'
class documentationTemplate extends React.Component {
  render() {
    const documentation = get(this, 'props.data.documentation')
    const site = get(this, 'props.data.site')
    const title = get(documentation, 'frontmatter.title')
    const siteTitle = get(site, 'meta.title')

    return (
      <div>
        <Helmet title={`${title} | ${siteTitle}`} />
        <SiteDocumentation {...this.props} />
        <Container>
          <Row>
          <Col className="text-center"><h2>Nos services</h2>
          <PageServices />
          </Col>
          </Row>
        </Container>
        <HomeBrands />
        <HomeNewsLetter/>
      </div>
    )
  }
}

export default documentationTemplate

export const pageQuery = graphql`
  query DocumentationByPath($path: String!) {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    documentation: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        layout
        title
        path
      }
    }
    allImageSharp(filter: {fluid: {originalName: {in: [
      "toro.png",
      "hunter.png"
      "rainbird.png",
      "irritrol.png",
      "hydrorain.png", 
      "irriglobe.png",
      "rachio.png",
      "ez-flo.png",
      "k-rain.png",
      "rewatec.png"
    ]}}}) {
      edges {
        node {
          fluid(maxWidth: 400) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
